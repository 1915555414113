.steady-footer {
  background-color: #1d2a52;
  color: #ccd0e3;

  //****************first content*****************
  .first-content {
    display: flex;
    flex-direction: column;
    padding: 30px 5%;

    .quick-links {
      ul {
        list-style: none;
        padding-left: 16px;
        span {
          border-left: 4px solid #26cfb1;
          height: auto;
          margin-right: 8px;
        }

        li {
          margin-bottom: 8px;
        }
      }
      h3 {
        color: #26cfb1;
      }
    }
  }

  //****************divider***********************
  .footer-divider {
    border-bottom: 1px solid lightgray;
    width: 100%;
  }

  //   ************second content****************
  .footer-copyright {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
    padding: 30px 5%;

    .copyright-container {
      p,
      a {
        line-height: 20px;
        font-size: 0.9rem;
      }
      text-align: center;
    }

    .privacy-container {
      display: flex;
      gap: 8px;
      justify-content: space-evenly;
      span {
        border-right: 1px solid #ccd0e3;
        height: auto;
      }
    }

    .social-container {
      display: flex;
      gap: 10%;
      justify-content: center;
    }
  }
}

@media only screen and (min-width: 600px) {
  .steady-footer {
    //****************first content*****************
    .first-content {
      display: flex;
      flex-direction: row;
      gap: 60px;
      padding: 30px 5%;

      .copyright-container {
        p,
        a {
          line-height: 20px;
          font-size: 0.9rem;
        }
        width: 522px;
      }

      .quick-links {
        ul {
          list-style: none;
          padding-left: 16px;
          span {
            border-left: 4px solid #26cfb1;
            height: auto;
            margin-right: 8px;
          }

          li {
            margin-bottom: 8px;
          }
        }
        h3 {
          color: #26cfb1;
        }
      }
    }

    //****************divider***********************
    .footer-divider {
      border-bottom: 1px solid lightgray;
      width: 100%;
    }

    //   ************second content****************
    .footer-copyright {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0;
      padding: 30px 5%;

      .copyright-container {
        p,
        a {
          line-height: 20px;
          font-size: 0.9rem;
        }

        text-align: center;
      }

      .privacy-container {
        display: flex;
        gap: 8px;
        justify-content: space-evenly;
        a {
          line-height: 20px;
          font-size: 0.9rem;
        }
        span {
          border-right: 1px solid #ccd0e3;
          height: auto;
        }
      }

      .social-container {
        display: flex;
        gap: 30px;
        justify-content: center;
      }
    }
  }


}


@media only screen and (max-width: 997px) {

.logo-osm-header {
  height: 35px;
}


.show-mobile{
  display: block !important;
}

.show-web{
  display: none !important;
}

.calculator-container{
  padding: 30px 60px;
  min-width: none;
}

.savings-value{
  padding: 5px 0px;
  margin: 5px 0px;
}



}

@media only screen and (max-width: 800px) {

  .carousel-container {
    padding: 20px 10px !important;
  }

  .container-testimonial{
    max-width: calc( 100% - 60px ) !important;
  }

}

@media only screen and (max-width: 600px) {
  .logo-osm-header {
    height: 35px;
  }

  .btn-mobile{
    height: 35px !important;
  }

}



@media only screen and (max-width: 567px) {
  .container-testimonial{
    max-width: calc( 100% - 60px ) !important;
  }

  .carousel-container{
    padding: 20px 15px !important;
  }
}



@media only screen and (max-width: 400px) {
  
  .logo-osm-header {
    height: 30px;
  }
}