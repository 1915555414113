// variables.scss
$primaryColor: #2e458a;
$secondaryColor: #3498db;
$headerColor: #ffffff;
$mainLayoutColor: #f8f9fa;
$textColor: #000000;
$white-color: #ffffff;

$nav-dark-bg: #003366;
$nav-dark-text-color: #ffffff;
$menu-dark-bg: $nav-dark-bg;
$menu-dark-color: $nav-dark-text-color;
$menu-dark-highlight-color: $secondaryColor;
$menu-dark-arrow-color: $nav-dark-text-color;
$hor-nav-text-color: #fffffd;

$border-radius-base: 6px;
$border-radius-sm: 4px;
$border-radius-lg: 10px;
$sidebar-width: 280px;
$gx-customizer-width: 370px;
$screen-md-max: 992px;
$screen-xs-max: 575.98px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;

$gray: #b1b1b1;
$error: #ff735d;
$green: #39d3b7;
$white: #ffffff;
$lightgray: #d9d9d9;
