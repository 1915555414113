.faq-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: 30px 0px;
  
    .blue-line {
      width: 150px;
      border-bottom: 2px solid #2e458a;
    }
  
    h2 {
      color: #2e458a;
      margin: 0px;
    }
  }

  .collapse-container{
    padding: 0 10% 60px;
  }