@import './utilities/_variables.scss';
@import 'components/Button';
@import 'components/Header';
@import 'components/Footer';

@import 'components/Landing';
@import 'components/FirstSection.scss';
@import 'components/SecondSection.scss';
@import 'components/SteadyFooter.scss';
@import 'components/Form.scss';
@import 'components/TestimonialSection.scss';
@import 'components/dynamicLanding.scss';
@import 'components/FaqSection.scss';
@import 'components/ObpCalculator.scss';


@import 'layout/layouts';
// @import 'layout/drawer';

.app-header {
  background-color: $primaryColor; // Assuming $primaryColor is defined in Button.scss
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:where(.css-dev-only-do-not-override-1adbn6x).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background-color: #e6f4ff;
  border-color: #e6f4ff;
  color: $secondaryColor;
}

:where(.css-dev-only-do-not-override-1adbn6x).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: $secondaryColor;
}

.anticon {
  color: #ffffff;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: $secondaryColor;
}

:where(.css-dev-only-do-not-override-1adbn6x).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon {
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

.lottieContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 1000;
  position: fixed;

  img {
    width: 400px;
    height: 200px;
  }
}
