
@import '../utilities/_variables.scss';


.footer{
   background-color: $headerColor;
   padding: 20px 100px;
   display: flex;
   flex-direction: column;
   align-items: center;
   .content-footer{ 
    max-width: 900px;   
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    .image-column{
        text-align: center;
        width: 200px;
        p{  
            width: 100%; 
            color: $textColor;
            line-height: 1;
            margin-bottom: 0;
            text-align: center;
        }
        img{
            height: 60px;
            width: auto; 
        }
    }
    .disclosure{
        width: 700px; 
        p{  
            
            color: $textColor;
            text-align: justify;
        }
    }
   }
 
}