.subscription-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 10px 13% 20px 13%;
}

.ghost-text{
  font-size: 0.8rem !important;
  color: #626262;
}

.title-container {
  color: #284485;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.logo-container-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 50px;
  max-height: 60px;
  padding: 5px 0px;
}

.disclaimer {
  font-size: 0.7rem;
  color: #959595;
  text-align: start;
}

.dsclaimer-subscription {
  font-size: 0.7rem;
  color: #959595;
}

.flex {
  display: flex;
}

.justify-content {
  justify-content: center;
}

.align-items {
  align-items: center;
}

.logo-osm-header {
  margin: 10px 0px;
  height: 50px;
}

.logo-osm-header-suscribe {
  margin: 10px 0px;
  height: 100px;
}

.osm-footer {
  padding: 0px 10%;
}

.osm-landing-content-container {
  min-height: 60vh;
}

.package-cards-container {
  display: flex;
  justify-content: space-evenly;
}

.package-card {
  border: 1px solid lightgray;
  padding: 20px;
  border-radius: 8px;
  width: 250px;
}

.package-title {
  color: #2e458a;
  font-weight: 600;
}

.features-container {
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.step-description {
  margin-bottom: 30px;
  text-align: center;
}

.cancel-gray-button {
  background-color: lightgray;
  border: none;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.congratulation-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  min-height: 300px;

  p {
    text-align: center;
    font-size: 1rem;
  }
}

.obp-landing-container {
  display: flex;
  flex-direction: column;
}

.obp-landing-navbar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 60px;
  max-height: 100px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 5%;
  padding-right: 5%;

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-right {
    display: flex;
    justify-content: right;
    align-items: center;
  }
}

.mobile-subscription {
  display: none;
}

.laptop-subscription {
  display: block;
}

.successfull-subscription {
  h3 {
    font-weight: bold;
  }

  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  gap: 16px;
}

@media only screen and (max-width: 600px) {
  .obp-landing-navbar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 60px;
    max-height: 100px;
    padding-top: 5px;
    padding-bottom: 5px;

    .flex-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mobile-hidden {
    display: none !important;
  }

  .disclaimer {
    text-align: center;
  }

  .mobile-subscription {
    display: block;
  }

  .laptop-subscription {
    display: none;
  }
}

.field-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
  gap: 5px;

  label {
    font-size: 15px;
    color: #003366;
    text-align: left;
    line-height: 1.6;
    margin-bottom: 0px;
    font-weight: 300;
    width: 100%;
  }

  input,
  select {
    max-width: 300px;
    width: 100%;
    height: 35px;
    font-size: 0.95rem;
    border: 1px solid #d2d2d2;
    background-color: #ffffff;
    border-radius: 2px;
    padding: 0 0 0 16px;
    margin-left: 0 !important;
  }

  input::placeholder {
    color: #d2d2d2;
    opacity: 1;
  }

  input::-ms-input-placeholder {
    color: #d2d2d2;
  }

  select::placeholder {
    color: #d2d2d2;
    opacity: 1;
  }

  input:focus-visible {
    outline: 0px;
  }
  input:focus,
  select:focus {
    border: 2px solid rgb(46, 69, 138);
  }

  option {
    height: 35px;
    background-color: whitesmoke;
    color: rgb(46, 69, 138);
  }
}

.save-button {
  background-color: rgb(46, 69, 138);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: 30px;

  &:hover {
    background-color: rgb(46, 69, 138);
    color: #fff !important;
  }

  &:disabled {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    box-shadow: none;
  }

  &:active,
  &:focus {
    background-color: rgb(46, 69, 138);
    color: white;
  }
}

.cancel-button {
  background-color: lightgray;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: 30px;
  margin-right: 10px;

  &:hover {
    background-color: lightgray;
    color: #fff !important;
  }
}
