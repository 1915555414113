.landing-second-section {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 5% 5%;

  .image-container {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .custom-li {
    display: flex;
    gap: 8px;
    justify-content: start;
    align-items: center;
  }

  h1,
  h2 {
    color: #2e458a;
    margin: 0;
  }
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media only screen and (min-width: 600px) {
  .landing-second-section {
    align-items: start;
    display: grid;
    grid-template-columns: 50% 50%;

    .obp-house {
      max-width: 100%;
    }

    .image-container {
      width: 100%;

      img {
        width: 90%;
      }
    }

    .custom-li {
      display: flex;
      gap: 8px;
      justify-content: start;
      align-items: start;

      p {
        margin: 0 0 12px 0;
      }
    }
  }
}
