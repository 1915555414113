.landing-first-section {
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  min-height: fit-content;

  background-image: url('../../../public/images/hero.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  // white container
  .white-container {
    background-color: rgba(255, 255, 255, 0.75);
    height: 100%;
    width: 100%;
    padding: 5% 5%;

    .title {
      font-size: 2rem !important;
      color: #2e458a;
      text-align: center;
      margin: 0px;
    }

    .subtitle {
      font-size: 1.5rem !important;
      color: #2e458a;
      text-align: center;
      margin: 0px;
    }

    p {
      text-align: center;
      font-size: 18px;
    }

    ol {
      li {
        margin-bottom: 8px;
      }
    }
  }
}

.green-text {
  color: #47d7ac;
}

@media only screen and (min-width: 600px) {
  .landing-first-section {
    background-color: #f4f4f4;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: start;
    align-items: center;

    min-height: fit-content;

    background-image: url('../../../public/images/hero.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;

    // white container
    .white-container {
      background-color: rgba(255, 255, 255, 0.8);
      height: 100%;
      width: 50%;
      padding: 5% 5%;

      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: start;
      align-items: start;

      .title {
        font-size: 2rem !important;
        color: #2e458a;
        text-align: center;
        margin: 0px;
      }

      p {
        text-align: start;
        font-size: 18px;
      }

      ol {
        li {
          margin-bottom: 8px;
        }
      }
    }
  }
}
