@import '../utilities/variables';

.testimonial-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.testimonials-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 30px 0px;

  .blue-line {
    width: 150px;
    border-bottom: 2px solid #2e458a;
  }

  h2 {
    color: #2e458a;
    margin: 0px;
  }
}

.carousel-container {
  padding: 20px 5%;
 
}

.container-testimonial {
  position: relative;
  min-width: 200px;
  max-width: 290px;
  width: calc(100% - 40px);
  min-height: 300px;
  background-color: #fff;

  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 0px 50px;
  background: #fff;

  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.25);

  .marks {
    position: absolute;
    top: 25px;
    z-index: 1;
    left: 16px;
  }

  .card-testimonial-text {
    z-index: 2000;
    position: relative;
    text-align: center;
    font-size: 1rem;
  }

  .card-testimonial-autor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    h3,
    p {
      font-size: 0.9rem;
      margin: 0 !important;
    }

    h3 {
      color: $primaryColor;
    }

    .state {
      font-style: italic;
    }

    .date {
      font-size: 12px;
    }
  }
}
