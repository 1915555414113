@import '../utilities/_variables.scss';

.header{
    background-color: $headerColor;
    text-align: center;
    img{
      height: 60px;
      width: auto; 
      margin-top: 2px;
    }
}
