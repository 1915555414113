@import '../utilities/_variables.scss';

.button {
  border-color: #39D3B7;
  background-color: #39D3B7;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;

  &:hover {
    background-color: darken($primaryColor, 10%);
  }
}




.btn-green{
  line-height: 20px !important;
  color: #ffffff;
  border-color: #39D3B7;
  background-color: #39D3B7;
  font-weight: 600;
}

.btn-green:hover{
  color: #ffffff !important;
  border-color: #31baa1 !important;
  background-color: #31baa1 !important;
}

.btn-green:active{
  color: #ffffff !important;
  border-color: #31baa1 !important;
  background-color: #31baa1 !important;
}

.btn-green:disabled {
  color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
  background-color: #ffffff;
}