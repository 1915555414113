.main-layout{
   background-color: $mainLayoutColor; 
   min-height: 100vh;
}

.main-content{
  background-color: $mainLayoutColor; 
  min-height: calc( 100vh - 128px);
  padding: 40px 60px;
}


.step{
  width: 100%;
  height: auto;
  overflow-y: none;
}


.lottieContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 100000;
  position: fixed;

  img{
    width: 400px;
    height: 200px;
  }
}

.gx-loader-view {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: auto;
}


.remove-x-overflow{
  overflow-x: hidden;
}