@import '../utilities/variables';

.claim-error-msg {
  color: #e94f4f;
  font-size: 15px;
  text-align: left;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 16px;
}

.label {
  font-size: 16px;
  color: #2e458a;
  text-align: left;
  line-height: 1.6;
  margin-bottom: 0px;
  width: 100%;
}

.tin-radio-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0px;
  .radio-label {
    display: flex;
    align-items: center;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.blue {
  color: $primaryColor !important;
}

.text-left {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
}

.start {
  text-align: start !important;
}

.align-title {
  padding-left: 20px;
}

.form-title {
  width: 100%;

  h3 {
    color: $primaryColor !important;
    font-weight: 700;
    text-align: left !important;
    margin-top: 3px;
    margin-bottom: 8px;
  }

  .edit {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  span {
    text-decoration: underline;
    color: $primaryColor;
    cursor: pointer;
    margin-top: 3px;
  }

  .edit-button {
    text-decoration: underline;
    color: $primaryColor;
    cursor: pointer;
    margin-top: 3px;
    background-color: transparent;
    border-color: transparent;
  }
}

.field-content-checkbox {
  margin: 10px 0px;
  .checkbox-text {
    font-size: 16px;
    color: $primaryColor;
    font-weight: 300;
  }
}

.card-info-form {
  width: 100%;
  text-align: left;

  .label {
    font-size: 0.95rem;
    color: $primaryColor;
  }

  p {
    margin: 8px 0px !important;
  }
}

.edit-form {
  width: 100%;
  .ant-upload-list-item {
    height: 40px !important;
  }
  .anticon {
    color: $gray !important;
  }
  .ant-upload-icon {
    color: $textColor !important;
  }
  .ant-upload-list-item-name {
    color: $textColor !important;
  }
}

.save-confirmed {
  font-size: 0.95rem;
  color: $green;
}

.save-confirmed {
  font-size: 0.95rem;
  color: $green;
}

.field-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;

  label {
    font-size: 16px;
    color: $primaryColor;
    text-align: left;
    line-height: 1.6;
    margin-bottom: 0px;
    width: 100%;
  }

  .picker-form {
    padding: 6px 5px 5px 0px;
    margin: 0;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
    background-color: #f5f5f5;
    width: 100% !important;
    height: 35px !important;
    input {
      padding: 3px 0 0 16px !important;
      margin-left: 0;
      font-size: 0.95rem;
    }
    .anticon {
      color: $lightgray;
    }
  }

  input,
  select {
    width: 100% !important;
    height: 35px !important;
    font-size: 0.95rem;
    border: 1px solid #d2d2d2;
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 0 0 0 16px;
    margin-left: 0 !important;
    margin-bottom: 5px;
  }

  input:focus-visible {
    outline: 0px;
  }
  input:focus,
  select:focus {
    border: 2px solid rgb(46, 69, 138);
  }

  option {
    height: 35px;
    background-color: whitesmoke;
    color: rgb(46, 69, 138);
  }
}

.error {
  margin-top: 3px;
  color: $error;
}

.upload-button {
  .anticon {
    color: #222222;
  }
}

.field-error {
  input,
  select {
    border: 1px solid $error !important;
  }
}

.file-w9 {
  margin: 10px 0px;
  padding: 3px;
  background-color: #eeeeee;
}

.info-user {
  color: $primaryColor;
}

.routing-bank {
  font-weight: 500;
  .bank-label {
    font-size: 1rem;
  }
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  padding: 5px 10px 5px 0px;

  .cancel-button {
    background-color: $lightgray;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    padding: 0 1rem;
    font-weight: 400;
    line-height: 1.5;
    height: 30px;
    margin-right: 10px;

    &:hover {
      background-color: darken($lightgray, 10%);
      color: #fff !important;
    }
  }

  .save-button {
    line-height: 20px !important;
    color: #ffffff;
    border-color: #39D3B7;
    background-color: #39D3B7;
    border-radius: 5px;

    border-radius: 5px;
    font-size: 1rem;
    padding: 0 1rem;
    font-weight: 400;
    line-height: 1.5;
    height: 30px;

    &:hover {
      color: #ffffff !important;
      border-color: #31baa1 !important;
      background-color: #31baa1 !important;
    }

    &:active{
      color: #ffffff !important;
      border-color: #31baa1 !important;
      background-color: #31baa1 !important;
    }


    &:disabled {
      cursor: not-allowed;
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.25);
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: none;
    }
  }
}

.blank-w9-anchor {
  color: $primaryColor;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.section-form {
  h2 {
    font-size: 1.25em;
    color: $primaryColor;
    margin-left: 20px;
  }
  h3 {
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 600px) {
  .button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    padding: 5px 5px 0px 5px;
    .cancel-button {
      background-color: $lightgray;
      color: #fff;
      border: none;
      border-radius: 5px;
      font-size: 1rem;
      padding: 0 1rem;
      font-weight: 400;
      line-height: 1.5;
      height: 30px;
      margin-right: 10px;

      &:hover {
        background-color: darken($lightgray, 10%);
        color: #fff !important;
      }
    }

    .save-button {
      line-height: 20px !important;
      color: #ffffff;
      border-color: #39D3B7;
      background-color: #39D3B7;
      border-radius: 5px;
      font-size: 1rem;
      padding: 0 1rem;
      font-weight: 400;
      line-height: 1.5;
      height: 30px;

      &:hover {
        color: #ffffff !important;
        border-color: #31baa1 !important;
        background-color: #31baa1 !important;
      }

      &:active{
        color: #ffffff !important;
        border-color: #31baa1 !important;
        background-color: #31baa1 !important;
      }

      &:disabled {
        cursor: not-allowed;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        background-color: rgba(0, 0, 0, 0.04);
        box-shadow: none;
      }
    }
  }
}
