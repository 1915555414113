.dynamic-landing-first-section {
  display: flex;
  flex-direction: column;

  .center-container {
    display: flex;
    justify-content: center;

    .title {
      color: #224772;
    }
  }

  .grid-two-cols {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .container-padding {
      padding: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
    }
  }
}

.package-cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 50px 0px;
}

.package-card {
  border: 1px solid lightgray;
  padding: 20px;
  border-radius: 20px;
  width: 250px;
  box-shadow: 0px 1px 12px 0px rgba(147, 147, 147, 0.25);
  height: 500px;

  position: relative;
  overflow: hidden;
}

.image-container {
  display: flex;
  justify-content: center;
}

.package-title {
  color: #2e458a;
  font-weight: 600;
  text-align: center;
  font-size: 1.2rem;
}

.feature-item {
  display: grid;
  grid-template-columns: 10% 1fr;
}

.package-description {
  text-align: start;
  font-style: italic;
}

.included-text {
  text-align: center;
  color: #2e458a;
}

.features-container {
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text-step-description-container {
  display: flex;
  justify-content: center;
}

.second-section-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container {
  background-color: white;
  padding: 15px 60px;
  border-radius: 8px;
  box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.3);
}

.center-button-force {
  display: flex;
  justify-content: center !important;
  flex-direction: column;

  p {
    display: flex;
    justify-content: start;
    width: 100%;
  }
}

.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5px 5% 15px 5%;

  h1 {
    color: #2e458a;
    margin-bottom: 8px;
  }

  .hero-text {
    width: clamp(291px, 90%, 900px);
    margin-top: 2px;
    text-align: start;
  }
}

.hidden {
  display: none;
}

.form-email {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .field-content {
    margin-bottom: 0px;
    width: 300px;

    input {
      width: calc(100% - 17px) !important;
      height: 35px !important;
      font-size: 0.95rem;
      border: 1px solid #d2d2d2;
      background-color: #f5f5f5;
      border-radius: 4px;
      padding: 0 0 0 16px;
      margin-left: 0 !important;
      margin-bottom: 0px;
    }
  }

  .button-container {
    height: 37px;

    .save-button {
      height: 37px !important;
    }
  }
}

.text-note {
  font-size: 14px;
  padding: 0% 33%;
  margin-bottom: 2px;
}

.dynamic-second-section {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 5% 5%;
}

.cards-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: #2e458a;
    width: 90%;
    text-align: center;
    margin-bottom: 5px;
  }
}

.description-list {
  font-style: italic;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.alert-info {
  width: clamp(200px, 100%, 480px);
  text-align: left;
}

.calculator-equation-row {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 14px 0px;
}

@media only screen and (min-width: 600px) {
  .dynamic-second-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 5% calc(100% - 1230px);
  }

  .form-email {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px;

    .field-content {
      margin-bottom: 0px;
      width: 300px;

      input {
        width: calc(100% - 17px) !important;
        height: 35px !important;
        font-size: 0.95rem;
        border: 1px solid #d2d2d2;
        background-color: #f5f5f5;
        border-radius: 5px 0px 0px 5px;
        padding: 0 0 0 16px;
        margin-left: 0 !important;
        margin-bottom: 0px;
      }
    }

    .button-container {
      height: 35px;

      .save-button {
        height: 35px !important;
        border-radius: 0px 5px 5px 0px !important;
      }
    }
  }
}
