.blue-title {
  color: #2e458a;
  margin: 0 0 15px 0 !important;
}

.w-content {
  width: max-content;
}

.calculator-container {
  border: 1px solid #adadad;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: clamp(317px, 100%, 320px);
  padding: 10px 15px 10px 15px;
  margin: 0 2px;

  img {
    width: 16px;
    height: 16px;
    margin: 3px 0;
  }

  .blue-title {
    color: #2e458a;
    margin: 0 0 15px 0 !important;
    font-size: 1.3em;
  }
  .calculator {
    h2 {
      color: #2e458a;
      margin: 0px;
    }

    p {
      color: #686868;
    }
    input,
    select {
      background-color: white;
      border-color: #d9d9d9 !important;
      & ::placeholder {
        color: #d9d9d9 !important;
      }
    }
  }
}

.select-years-of-obp {
  // width: calc(100% + 5px) !important;
  border-radius: 8px !important;
  .ant-select-selection-search-input {
    width: 100% !important;
    max-width: 100% !important;
  }
  .ant-select-selector {
    border-radius: 4px !important;
    height: 35px !important;
    .ant-select-selection-item {
      padding-left: 5px !important;
    }
  }

  input {
    width: 100%;
  }
}

.button-primary {
  padding: 12px 16px;
  background-color: #2e458a;
  color: white;
  font-size: 1em;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.button-calculator {
  padding: 12px 16px;
  background-color: #2e458a;
  width: 100%;
  height: 40px;
  color: white;
  font-size: 1.2em;
  line-height: 0.5;
  font-weight: 900;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  & :hover {
    background-color: #23356a;
  }

  & :focus {
    background-color: #23356a;
  }
}

.result {
  h2 {
    color: #2e458a;
    margin: 0px;
  }
}

.error-msg {
  color: #e94f4f;
  font-size: 15px;
  text-align: left;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 16px;
}

.calculator-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.show-mobile {
  display: none;
}

.show-web {
  display: block;
}

.see-details {
  border: none;
  font-weight: 600;
  text-decoration: underline;
  color: #2e458a;
  cursor: pointer;
}

.see-details:focus-visible {
  border: none;
  outline: none;
}

.result-value {
  font-weight: 800;
  margin: 0 !important;
}

.savings-value {
  font-size: 2.5em;
  font-weight: 900;
  color: #000;
  margin: 0px 0px 12px 0px;
}

.total-value {
  font-size: 1.3em;
  font-weight: 900;
  color: #000;
  margin: 0 !important;
  padding: 3px 0 !important;
  color: #2e458a;
}
.label-value {
  font-size: 0.9em;
  font-weight: 300;
  color: #686868;
  padding: 0 !important;
  margin: 0 !important;
}

.constant-value {
  font-size: 1em;
  font-weight: 600;
  color: #000;
  padding: 0 !important;
  margin: 0 !important;
}

.savings-label-value {
  font-size: 0.9em;
  font-weight: 300;
  color: #686868;
  padding: 0 !important;
  margin: 0px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin: 15px 0px;

  .text {
    width: 70%;
    font-size: 0.9em;
    font-weight: 300;
    color: #686868;

    padding: 0 !important;
    margin: 0px;
  }
}
